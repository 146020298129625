import * as React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const Section = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const ArticlesWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  display: flex;

  @media (max-width: 960px) {
    flex-direction: column;
  }

  @media (min-width: 961px) {
    flex-direction: row;
  }
`

const Article = styled.article`
  max-width: 460px;
  padding: 0 10px;
  margin: 0 auto;
  box-sizing: border-box;
`

const imageProps = {
  loading: 'eager' as const,
  fadeIn: false
}

const Biografien = ({ data: { kathrein2, anneKathrein, team }, ...props }) => {
  return (
    <Section>
      <ArticlesWrapper>
        <Article>
          <p style={{ fontSize: '15px' }}>
            <i style={{ fontWeight: 'bold' }}>
              „Besonders zeichnet sie ein blühend schöner Ton verbunden mit einem starken Ausdruckswillen aus“
            </i>
            <br />
            Thomas Brandis
          </p>
          <Img {...imageProps} fluid={kathrein2.edges[0].node.childImageSharp.fluid} />
          <p style={{ fontSize: '15px' }}>
            Innigkeit und Spass am Spielen – das steht für Kathrein Allenberg an oberster Stelle. Ob Sonaten,
            Streichquartett oder Tango, ob in der „Kammer“, im Konzertsaal oder auf der Theaterbühne.
            <br />
            <br />
            Kathrein Allenberg studierte bei Thomas Brandis, Konzertmeister der Berliner Philharmoniker, an der UdK
            Berlin und spielte an der Deutschen Oper Berlin.
            <br />
            <br />
            Die Auseinandersetzung mit der „historischen Aufführungspraxis“ führte zu einem regen Konzertleben auf der
            Barockgeige. Auf Instrumenten der Berliner Musikinstrumentenmuseums erschien 2011 die CD „Quartette für
            Preußen“.
            <br />
            <br />
            Theater gab es zuletzt am Berliner Ensemble in „Und der Haifisch, der hat Zähne“. Dort spielte sie „Sieben
            Stücke aus der 3 Groschenoper für Violine und Klavier“ von Stefan Frenkel.
            <br />
            <br />
            Sie arbeitete mit György Kurtag in Budapest an dessen „Kafkafragmenten“ für Sopran und Violine, jahrelang
            war sie Geigerin des Berliner Neue Musik Ensembles „work in progress“.
          </p>
        </Article>
        <Article>
          <p style={{ fontSize: '15px' }}>
            <i style={{ fontWeight: 'bold' }}>„Selten habe ich jemand so schön Schubert spielen gehört“</i>
            <br />
            Emanuel Hurwitz
          </p>
          <Img {...imageProps} fluid={anneKathrein.edges[0].node.childImageSharp.fluid} />
          <p style={{ fontSize: '15px' }}>
            Anne-Kathrein Jordan ist eine begehrte Kammermusikpartnerin und einfühlsame Liedbegleiterin – geistreich,
            überraschend, verlässlich und spritzig.
            <br />
            <br />
            Als Pädagogin und Korrepetitorin arbeitet sie beim Windsbacher Knabenchor.
            <br />
            <br />
            Anne-Kathrein Jordan studierte Klavier und Kammermusik in Nürnberg und Frankfurt, ein Gaststudium für
            Hammerflügel führte sie an das Conservatoire supérieur nach Paris .<br />
            <br />
            Sie setzt sich mit zeitgenössischer Musik auseinander und nimmt regelmässig für den Bayerischen Rundfunk
            auf.
            <br />
            <br />
            CD-Produktionen: „Duo Biloba“ mit Werken für Violoncello und Klavier, sowie „Lieder von Werner Bühler“.
            <br />
            <br />
            Mit großem Spaß tritt sie auch als Pianistin des Frauenkabaretts „Die Avantgardinen“ auf die Bühne.
          </p>
        </Article>
      </ArticlesWrapper>
    </Section>
  )
}

export default Biografien

export const query = graphql`
  query {
    kathrein2: allFile(filter: { base: { eq: "kathrein2.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 460) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    anneKathrein: allFile(filter: { base: { eq: "anneKathrein.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 460) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    team: allFile(filter: { base: { eq: "team.jpg" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
